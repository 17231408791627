html {
  scroll-behavior: smooth;
}

.wrapper {
  width: 70vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-auto-rows: minmax(auto, auto);
  grid-gap: 0.5em;
  /* default is stretch */
  justify-items: stretch;
  /* default is stretch */
  align-items: stretch;
}

.wrapper > div {
  background: rgb(211, 211, 211);
  padding: 1em;
}

.wrapper > div:nth-child(odd) {
  background-color: #ddd;
}

.box0 {
  /* border: 3px solid rgb(255, 0, 0); */
  grid-row: 8/1;
  grid-column: 1;
}

.box1 {
  /* border: 3px solid rgb(255, 0, 0); */
  align-self: center;
}

.box1 img {
  width: 100%;
}

.left-panel {
  position: sticky;
  top: 30px;
  /* text-align: center; */
  /* border: 3px solid blue; */
  /* background-color: red; */
}

.left-panel li {
  font-size: 1.25rem;
  list-style: none;
  padding-bottom: 20px;
  padding-left: 10px;
}

.left-panel a {
  text-decoration: none;
  color: black;
}

.left-panel a:hover {
  text-decoration: underline;
}

.left-panel img {
  width: 100%;
  border-radius: 50%;
}

.contact {
  padding-left: 15px;
}

@media screen and (max-width: 960px) {
  .wrapper {
    width: 90vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(auto, auto);
    grid-gap: 0.5em;
    /* default is stretch */
    justify-items: stretch;
    /* default is stretch */
    align-items: stretch;
  }

  /* .box0 {
    display: none;
  } */
}
